import React from 'react';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkPrimaryButton, UitkSecondaryButton } from 'uitk-react-button';
import { UitkSpacing } from 'uitk-react-spacing';
const DocumentErrorView = ({
  errorResponse
}) => {
  const {
    messageText: {
      text
    },
    descriptionText: {
      text: descriptionText
    },
    buttons
  } = errorResponse;
  const getPrimaryButton = (message, url) => React.createElement(UitkPrimaryButton, {
    tag: "a",
    href: url,
    key: url
  }, message);
  const getSecondaryButton = (message, url) => React.createElement(UitkSecondaryButton, {
    tag: "a",
    href: url,
    key: url
  }, message);
  const getButton = button => {
    const actionUrl = button.action.resource.value;
    if (button.__typename === 'UIPrimaryButton') {
      return getPrimaryButton(button.primary, actionUrl);
    }
    return getSecondaryButton(button.primary, actionUrl);
  };
  return React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    className: "height-full",
    space: "two"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkHeading, {
    tag: "h2",
    size: 4
  }, text)), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    whiteSpace: "pre-line",
    align: "center"
  }, descriptionText)), React.createElement(UitkSpacing, {
    margin: "four"
  }, React.createElement(UitkLayoutFlex, {
    direction: "row",
    alignItems: "center",
    justifyContent: "center",
    space: "four",
    key: "key"
  }, buttons.map(button => getButton(button)))));
};
export default DocumentErrorView;