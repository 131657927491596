import React, { useEffect } from 'react';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing } from 'uitk-react-spacing';
import { useBexApi } from '@shared-ui/bex-api-context';
import { ErrorBoundary } from 'bernie-view';
import useNavigationParams from '../../hooks/useNavigationParams';
import EgMarkdownRenderer from '@eg-markdown-renderer/core';
import LoadingSkeleton from '../documentation-page/loading-skeleton';
import { useDocumentQuery } from '../../graphql/__generated__/types';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import Footer from '../footer';
import MessageBanner from '../common/message-banner';
import useAnalytics from '../../hooks/analytics/useAnalytics';
import { useMarkdownRendererOptions } from '../../hooks/useMarkdownRendererOptions';
import HeaderTitle from '../header-title';
import DocumentationTemplateRenderer from '@documentation-template-renderer/core';
import DocumentErrorView from './document-error-view';
import { GenericErrorMessage } from '../common/generic-error-message';
import { useDarkMode } from 'hooks/useDarkMode';
const HomeDocumentView = ({
  showFooter = true
}) => {
  var _a, _b;
  const darkMode = useDarkMode();
  const {
    hubId,
    hash,
    documentId
  } = useNavigationParams();
  const {
    context
  } = useBexApi();
  const {
    data,
    loading,
    error
  } = useDocumentQuery({
    variables: {
      context,
      hubId,
      documentId
    }
  });
  const analytics = useAnalytics();
  const {
    componentsOptions
  } = useMarkdownRendererOptions(hubId);
  useEffect(() => {
    var _a;
    if (!hash) (_a = document === null || document === void 0 ? void 0 : document.getElementById('document')) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
  }, [documentId]);
  const documentTypeName = (_b = (_a = data === null || data === void 0 ? void 0 : data.documentation) === null || _a === void 0 ? void 0 : _a.document) === null || _b === void 0 ? void 0 : _b.__typename;
  useEffect(() => {
    if (!loading && !error && documentTypeName === 'DocumentationDocumentSuccessResponse') {
      analytics.trackPageView();
    }
  }, [documentId, loading, error]);
  if (loading || !data) {
    return React.createElement(UitkLayoutGrid, {
      columns: 10
    }, React.createElement(UitkLayoutGridItem, {
      ...documentGridColumnProps
    }, React.createElement(UitkSpacing, {
      ...documentContainerSpacingProps
    }, React.createElement("div", null, React.createElement(LoadingSkeleton, null)))));
  }
  if (documentTypeName === 'DocumentationDocumentErrorResponse') {
    return React.createElement(DocumentErrorView, {
      errorResponse: data.documentation.document
    });
  }
  const {
    content,
    banner,
    title,
    intro,
    format
  } = data.documentation.document;
  if (format === 'json') {
    return React.createElement(React.Fragment, null, React.createElement(MessageBanner, {
      message: banner === null || banner === void 0 ? void 0 : banner.message,
      iconName: banner === null || banner === void 0 ? void 0 : banner.icon.token,
      spacing: {
        margin: {
          blockend: 'four'
        }
      }
    }), React.createElement(ErrorBoundary, {
      label: "document.body",
      key: documentId,
      fallback: React.createElement(GenericErrorMessage, null)
    }, React.createElement("div", {
      className: "md-renderer-wrapper"
    }, React.createElement(DocumentationTemplateRenderer, {
      jsonBody: content,
      darkMode: darkMode
    })), React.createElement(UitkLayoutFlex, {
      direction: "column"
    }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(Footer, null))))));
  }
  return React.createElement(UitkLayoutGrid, {
    columns: 10,
    style: {
      flexGrow: 1
    }
  }, React.createElement(UitkLayoutGridItem, {
    ...documentGridColumnProps
  }, React.createElement(UitkSpacing, {
    ...documentContainerSpacingProps
  }, React.createElement("div", null, React.createElement(MessageBanner, {
    message: banner === null || banner === void 0 ? void 0 : banner.message,
    iconName: banner === null || banner === void 0 ? void 0 : banner.icon.token,
    spacing: {
      margin: {
        blockend: 'four'
      }
    }
  }), React.createElement(ErrorBoundary, {
    label: "document.body",
    key: documentId,
    fallback: React.createElement(GenericErrorMessage, null)
  }, React.createElement("div", {
    className: "md-renderer-wrapper"
  }, React.createElement(EgMarkdownRenderer, {
    mdBody: content,
    componentsOptions: componentsOptions
  })))))), showFooter ? React.createElement(UitkLayoutGridItem, {
    ...footerGridColumnProps,
    style: {
      alignSelf: 'end'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(Footer, null))))) : React.createElement(React.Fragment, null), React.createElement(HeaderTitle, {
    documentTitle: title,
    content: intro
  }));
};
const documentGridColumnProps = {
  colSpan: {
    small: 'all'
  },
  colStart: {
    extra_large: 2,
    small: 1
  },
  colEnd: {
    extra_large: 8,
    small: 12
  },
  className: 'large-max-width'
};
const footerGridColumnProps = {
  ...documentGridColumnProps,
  colSpan: 'all',
  colStart: 2,
  rowStart: 0,
  rowEnd: 0
};
const documentContainerSpacingProps = {
  margin: {
    large: {
      block: 'six'
    }
  },
  padding: {
    small: {
      inline: 'four',
      block: 'three'
    },
    large: {
      block: 'three'
    },
    extra_large: {
      inline: 'unset'
    }
  }
};
export default HomeDocumentView;