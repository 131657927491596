import { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
export const useDarkMode = () => {
  const queryParameters = useQuery();
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
    if ((queryParameters === null || queryParameters === void 0 ? void 0 : queryParameters.get('darkMode')) === 'true') {
      document.cookie = 'darkMode=true;path=/';
    } else if ((queryParameters === null || queryParameters === void 0 ? void 0 : queryParameters.get('darkMode')) === 'false') {
      document.cookie = 'darkMode=false;path=/';
    }
    setIsDarkMode(document.cookie.includes('darkMode=true'));
  }, []);
  return isDarkMode;
};