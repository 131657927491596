class Storage {
  static getItem(key) {
    var _a;
    if (typeof window === 'undefined') return;
    const item = JSON.parse(localStorage.getItem(`${Storage.STORAGE_KEYS_PREFIX}${key}`));
    if ((item === null || item === void 0 ? void 0 : item.expiresOn) && Date.now() > item.expiresOn) {
      localStorage.removeItem(key);
      return null;
    }
    return (_a = item === null || item === void 0 ? void 0 : item.value) !== null && _a !== void 0 ? _a : null;
  }
  static setItem(key, value, ttl) {
    if (typeof window === 'undefined') return;
    return localStorage.setItem(`${Storage.STORAGE_KEYS_PREFIX}${key}`, JSON.stringify({
      value,
      expiresOn: ttl ? Date.now() + ttl : null
    }));
  }
  static clear() {
    localStorage.clear();
  }
}
Storage.STORAGE_KEYS_PREFIX = 'DOC-';
export default Storage;