import { useHistory, useLocation } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useBexApi } from '@shared-ui/bex-api-context';
import useAnalytics from './analytics/useAnalytics';
import { useApolloClient } from '@apollo/client';
import useNavigationParams from './useNavigationParams';
import { getSelectedTabGroups, getTabsQueryParams, markdownLinkOnClickHandler, sandboxDocumentQuery, sandboxLinkOnClickHandler, tabOnClickHandler } from 'utils/eg-markdown-renderer-utils';
import { useState } from 'react';
import { useDarkMode } from 'hooks/useDarkMode';
export const useMarkdownRendererOptions = hubId => {
  const isDarkMode = useDarkMode();
  const [, setDummyState] = useState(0);
  const {
    pathname
  } = useLocation();
  const {
    documentId
  } = useNavigationParams();
  const [contentVersion, setContentVersion] = useState(0);
  const {
    context
  } = useBexApi();
  const {
    trackApiExplorerEndpointExpand,
    trackApiExplorerRequestButtonClick,
    trackLinkSelected
  } = useAnalytics();
  const query = useQuery();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const componentsOptions = {
    apiExplorer: {
      onEndpointSectionExpand: endpointId => {
        trackApiExplorerEndpointExpand({
          endpointId
        });
      },
      onRequestButtonClick: (endpointId, serverUrl) => {
        trackApiExplorerRequestButtonClick({
          endpointId,
          serverUrl
        });
      }
    },
    trackableButtonLink: {
      onClick: (_, id) => {
        trackLinkSelected(id);
      }
    },
    link: {
      onClick: (href, e) => {
        e.preventDefault();
        markdownLinkOnClickHandler(href, pathname, hubId, history);
      }
    },
    trackableLink: {
      onClick(href, e, id) {
        e.preventDefault();
        trackLinkSelected(id);
        markdownLinkOnClickHandler(href, pathname, hubId, history);
      }
    },
    sandboxLink: {
      onClick: (e, href) => sandboxLinkOnClickHandler(e, href, query.get('path'), history)
    },
    heading: {
      hashLinks: {
        enabled: true,
        maximumLinkableLevel: 3
      }
    },
    tabs: {
      onTabChange: (group, value) => {
        tabOnClickHandler(group, value, history);
        //  need to force rerender of the documents and all the tabs component within
        setDummyState(prevState => prevState + 1);
      },
      selectedTabs: {
        ...(getSelectedTabGroups() || {}),
        ...(getTabsQueryParams(query) || {})
      }
    },
    documentationSandbox: {
      referencePath: query.get('path'),
      contentResolver: () => {
        return sandboxDocumentQuery({
          hubId,
          context,
          documentId,
          client: apolloClient,
          pathQueryParam: query.get('path')
        });
      }
    },
    events: {
      onContentUpdated: () => setContentVersion(contentVersion + 1)
    },
    common: {
      darkMode: isDarkMode
    }
  };
  return {
    componentsOptions,
    contentVersion
  };
};