import React from 'react';
import { UitkCard } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkText } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
const MessageBanner = ({
  message,
  iconName,
  spacing,
  messageStyle
}) => {
  if (!message) return null;
  return React.createElement(UitkSpacing, {
    ...spacing
  }, React.createElement("div", null, React.createElement(UitkCard, {
    padded: true,
    backgroundTheme: "secondary"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "two"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", {
    style: {
      position: 'relative',
      top: '-1.5px',
      ...messageStyle
    }
  }, React.createElement(UitkIcon, {
    name: iconName,
    theme: "default",
    size: UitkIconSize.SMALL
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkText, {
    theme: "default",
    size: 300
  }, message)))))));
};
export default MessageBanner;