import packageJson from '../../../package.json';
const commonEventData = (pageName, locale, article) => ({
  experience: {
    page_name: pageName,
    app_name: packageJson.name,
    app_version: packageJson.version
  },
  point_of_sale: {
    locale,
    eg_brand_name: 'expediagroup'
  },
  timestamps: {
    event_timestamp: new Date()
  },
  article: {
    document_id: article.documentId,
    article_link: article.articleLink
  }
});
export const events = {
  apiExplorerEndpointExpanded: (action, commonEventProperties) => {
    const {
      experience,
      ...commonData
    } = commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article);
    return {
      event: {
        event_version: '1.0.0',
        event_name: 'api_explorer_endpoint.opened',
        event_type: 'Interaction',
        event_category: commonEventProperties.hubId
      },
      experience: {
        page_name_detailed: action.endpointId,
        ...experience
      },
      ...commonData
    };
  },
  apiExplorerRequestButtonClick: (action, commonEventProperties) => {
    const {
      experience,
      ...commonData
    } = commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article);
    return {
      event: {
        event_version: '1.0.0',
        event_name: 'api_explorer_try_button.submitted',
        event_type: 'Interaction',
        event_category: commonEventProperties.hubId
      },
      experience: {
        page_name_detailed: action.endpointId,
        experience_platform: action.serverUrl,
        ...experience
      },
      ...commonData
    };
  },
  feedbackIntentAdded: (feedback, commonEventProperties) => ({
    feedback: {
      feedback_type: feedback.type
    },
    event: {
      event_version: '2.2.0',
      event_name: 'feedback_intent.added',
      event_type: 'Interaction',
      event_category: commonEventProperties.hubId
    },
    ...commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article)
  }),
  feedbackReasonAdded: (feedback, commonEventProperties) => ({
    feedback: {
      feedback_type: feedback.type,
      feedback_reason: feedback.reason
    },
    event: {
      event_version: '2.2.0',
      event_name: 'feedback_reason.added',
      event_type: 'Interaction',
      event_category: commonEventProperties.hubId
    },
    ...commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article)
  }),
  searchInputted: (search, commonEventProperties) => ({
    typeahead: {
      search_input: search.searchInput
    },
    event: {
      event_version: '1.3.0',
      event_name: 'property_selector_search.inputted',
      event_type: 'Interaction',
      event_category: commonEventProperties.hubId
    },
    ...commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article)
  }),
  pageViewed: commonEventProperties => ({
    event: {
      event_version: '1.0.0',
      event_name: 'documentation_page.viewed',
      event_type: 'Page View',
      event_category: commonEventProperties.hubId
    },
    ...commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article)
  }),
  signInSelected: commonEventProperties => {
    const {
      experience
    } = commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article);
    return {
      event: {
        event_type: 'Interaction',
        event_name: 'sign_in.selected',
        event_version: '1.3.0',
        event_category: commonEventProperties.hubId
      },
      experience
    };
  },
  signOutSubmitted: commonEventProperties => {
    const {
      experience
    } = commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article);
    return {
      event: {
        event_type: 'Interaction',
        event_name: 'sign_out.submitted',
        event_version: '1.8.0',
        event_category: commonEventProperties.hubId
      },
      experience
    };
  },
  linkSelected: (linkId, commonEventProperties) => {
    const commonData = commonEventData(commonEventProperties.pageName, commonEventProperties.locale, commonEventProperties.article);
    return {
      event: {
        event_type: 'Interaction',
        event_category: commonEventProperties.hubId,
        event_name: 'documentation_link.selected',
        event_version: '1.2.0',
        action_location: linkId
      },
      ...commonData,
      article: {
        ...commonData.article,
        article_name: commonEventProperties.article.articleName
      }
    };
  }
};