import { useBexApi } from '@shared-ui/bex-api-context';
import React from 'react';
import useNavigationParams from '../../hooks/useNavigationParams';
import { useHeaderQuery } from '../../graphql/__generated__/types';
import { Helmet } from 'react-helmet-async';
const HeaderTitle = ({
  documentTitle,
  content
}) => {
  const {
    context
  } = useBexApi();
  const {
    hubId
  } = useNavigationParams();
  const {
    data,
    error,
    loading
  } = useHeaderQuery({
    variables: {
      context,
      hubId
    }
  });
  if (!data || loading || error) {
    return null;
  }
  const {
    hubName
  } = data.documentation.header;
  return React.createElement(Helmet, null, React.createElement("title", null, documentTitle ? `${documentTitle} - Expedia Group ${hubName}` : `Expedia Group ${hubName}`), React.createElement("meta", {
    name: "description",
    content: content ? content : hubName
  }));
};
export default HeaderTitle;