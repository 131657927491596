import { useLocation } from 'react-router-dom';
import useHubContext from './useHubContext';
import { cleanUpDocumentId } from '../utils/common';
const useNavigationParams = () => {
  const {
    pathname,
    hash
  } = useLocation();
  const {
    hubInfo: {
      hubId,
      isHubPostfix
    }
  } = useHubContext();
  if (!hubId) return {
    hubId: '',
    pageName: '',
    tabId: '',
    documentId: '',
    currentPath: '',
    hash: ''
  };
  const pathSegments = pathname.split('/');
  // hub is postfix
  if (isHubPostfix) {
    const pageName = pathSegments.join('') === hubId ? 'Home' : 'Documentation';
    const tabId = pathSegments[2] || '';
    const isHomeDocument = tabId === '';
    const documentId = isHomeDocument ? 'home' : pathSegments.length > 2 && pathSegments.slice(2).join('/');
    return {
      hubId,
      pageName,
      tabId,
      documentId,
      currentPath: pathname,
      hash,
      isHubPostfix
    };
  }
  const isHomeDocument = pathname === '/';
  const documentId = isHomeDocument ? 'home' : cleanUpDocumentId(pathname);
  const tabId = pathSegments[1];
  const pageName = isHomeDocument ? 'Home' : 'Documentation';
  return {
    hubId,
    pageName,
    tabId,
    documentId,
    currentPath: pathname,
    hash,
    isHubPostfix
  };
};
export default useNavigationParams;