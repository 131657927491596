import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
/** Specifies the behavior of ALTER, which is used to run Abacus experiments on localization messages. */
export var AlterMode;
(function (AlterMode) {
  /** Localization messages will be replaced with their namespace and loc key. */
  AlterMode["DEBUG"] = "DEBUG";
  /** None of the ALTER experiments will be used when resolving localization messages. */
  AlterMode["NONE"] = "NONE";
  /** Both released and draft ALTER experiments will be used when resolving localization messages. */
  AlterMode["PREVIEW"] = "PREVIEW";
  /** This is the default. Only released ALTER experiments will be used when resolving localization messages. */
  AlterMode["RELEASED"] = "RELEASED";
})(AlterMode || (AlterMode = {}));
/** Possible aspect ratios of EGDS images */
export var AspectRatio;
(function (AspectRatio) {
  /** Aspect ratio 4:1 */
  AspectRatio["FOUR_ONE"] = "FOUR_ONE";
  /** Aspect ratio 4:3 */
  AspectRatio["FOUR_THREE"] = "FOUR_THREE";
  /** Aspect ratio 1:1 */
  AspectRatio["ONE_ONE"] = "ONE_ONE";
  /** Aspect ratio 16:9 */
  AspectRatio["SIXTEEN_NINE"] = "SIXTEEN_NINE";
  /** Aspect ratio 3:4 */
  AspectRatio["THREE_FOUR"] = "THREE_FOUR";
  /** Aspect ratio 3:2 */
  AspectRatio["THREE_TWO"] = "THREE_TWO";
  /** Aspect ratio 21:9 */
  AspectRatio["TWENTY_ONE_NINE"] = "TWENTY_ONE_NINE";
})(AspectRatio || (AspectRatio = {}));
export var AuthenticationState;
(function (AuthenticationState) {
  AuthenticationState["ANONYMOUS"] = "ANONYMOUS";
  AuthenticationState["AUTHENTICATED"] = "AUTHENTICATED";
  AuthenticationState["IDENTIFIED"] = "IDENTIFIED";
})(AuthenticationState || (AuthenticationState = {}));
/** UI Theme to be applied to a `UISectionContainer` or a `TripsSectionContainer`. */
export var ContainerTheme;
(function (ContainerTheme) {
  ContainerTheme["DEFAULT"] = "DEFAULT";
  ContainerTheme["IMPORTANT"] = "IMPORTANT";
})(ContainerTheme || (ContainerTheme = {}));
/** Valid device types */
export var DeviceType;
(function (DeviceType) {
  DeviceType["APP_PHONE"] = "APP_PHONE";
  DeviceType["APP_TABLET"] = "APP_TABLET";
  DeviceType["CHAT_APP"] = "CHAT_APP";
  DeviceType["DESKTOP"] = "DESKTOP";
  DeviceType["MOBILE_PHONE"] = "MOBILE_PHONE";
  DeviceType["MOBILE_TABLET"] = "MOBILE_TABLET";
  DeviceType["VOICE_APP"] = "VOICE_APP";
})(DeviceType || (DeviceType = {}));
/** Different types of duration span input validations. */
export var DurationSpanValidationType;
(function (DurationSpanValidationType) {
  /** Used to validate that a maximum input value is greater than a minimum input value. */
  DurationSpanValidationType["MAXIMUM_GREATER_THAN_MINIMUM"] = "MAXIMUM_GREATER_THAN_MINIMUM";
  /** Used to validate that the user input is less than or equal to some specified value. Example: Maximum 10 nights. */
  DurationSpanValidationType["MAXIMUM_VALUE"] = "MAXIMUM_VALUE";
  /** Used to validate that a minimum input value is less than a maximum input value. */
  DurationSpanValidationType["MINIMUM_LESS_THAN_MAXIMUM"] = "MINIMUM_LESS_THAN_MAXIMUM";
  /** Used to validate that the user input is greater than or equal to some specified value. Example: Minimum 2 nights. */
  DurationSpanValidationType["MINIMUM_VALUE"] = "MINIMUM_VALUE";
  /** Used to validate that the user input has a value. */
  DurationSpanValidationType["REQUIRED_VALUE"] = "REQUIRED_VALUE";
})(DurationSpanValidationType || (DurationSpanValidationType = {}));
/** Styles established in EGDS design docs. https://www.figma.com/file/bbPtPOgPN4sttR3BzDaTZc/Buttons */
export var EgdsButtonStyle;
(function (EgdsButtonStyle) {
  EgdsButtonStyle["PRIMARY"] = "PRIMARY";
  EgdsButtonStyle["SECONDARY"] = "SECONDARY";
  EgdsButtonStyle["TERTIARY"] = "TERTIARY";
})(EgdsButtonStyle || (EgdsButtonStyle = {}));
/**
 *
 *     Represents the variations of a card. Can be used with EGDSCard union members.
 *     https://www.figma.com/file/M3udFrUojCRwrcoAiPnQ3Yaw/Card?node-id=11143%3A833&t=blroVUeSfs4Eqnoj-0
 *
 */
export var EgdsCardThemes;
(function (EgdsCardThemes) {
  EgdsCardThemes["BORDERED"] = "BORDERED";
  EgdsCardThemes["PADDED"] = "PADDED";
})(EgdsCardThemes || (EgdsCardThemes = {}));
/** Defines how checkbox state should be displayed on client. */
export var EgdsCheckBoxState;
(function (EgdsCheckBoxState) {
  EgdsCheckBoxState["CHECKED"] = "CHECKED";
  EgdsCheckBoxState["INDETERMINATE"] = "INDETERMINATE";
  EgdsCheckBoxState["UNCHECKED"] = "UNCHECKED";
})(EgdsCheckBoxState || (EgdsCheckBoxState = {}));
/** Represents short day name. */
export var EgdsDayName;
(function (EgdsDayName) {
  EgdsDayName["FRI"] = "FRI";
  EgdsDayName["MON"] = "MON";
  EgdsDayName["SAT"] = "SAT";
  EgdsDayName["SUN"] = "SUN";
  EgdsDayName["THU"] = "THU";
  EgdsDayName["TUE"] = "TUE";
  EgdsDayName["WED"] = "WED";
})(EgdsDayName || (EgdsDayName = {}));
/** Represents the type of decorative in the text element. e.g. superscript */
export var EgdsDecorativeText;
(function (EgdsDecorativeText) {
  /** Renders with a background color to highlight the text */
  EgdsDecorativeText["HIGHLIGHT"] = "HIGHLIGHT";
  /** Renders with a line through the text */
  EgdsDecorativeText["LINE_THROUGH"] = "LINE_THROUGH";
  /** Renders as a subscript at the bottom of the text line */
  EgdsDecorativeText["SUBSCRIPT"] = "SUBSCRIPT";
  /** Renders as a superscript at the top of the text line */
  EgdsDecorativeText["SUPERSCRIPT"] = "SUPERSCRIPT";
  /** Renders with a line under the text */
  EgdsDecorativeText["UNDERLINE"] = "UNDERLINE";
})(EgdsDecorativeText || (EgdsDecorativeText = {}));
/**
 *
 *     DO NOT USE. Mobile doesn't support these html style. Client should handle heading tags.
 *
 *     https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography
 *
 */
export var EgdsHeadingStyle;
(function (EgdsHeadingStyle) {
  EgdsHeadingStyle["H1"] = "H1";
  EgdsHeadingStyle["H2"] = "H2";
  EgdsHeadingStyle["H3"] = "H3";
  EgdsHeadingStyle["H4"] = "H4";
  EgdsHeadingStyle["H5"] = "H5";
  EgdsHeadingStyle["H6"] = "H6";
  EgdsHeadingStyle["H7"] = "H7";
  EgdsHeadingStyle["H8"] = "H8";
})(EgdsHeadingStyle || (EgdsHeadingStyle = {}));
/** Represents where the icon should be places in a EGDS Standard Link */
export var EgdsIconPosition;
(function (EgdsIconPosition) {
  EgdsIconPosition["LEADING"] = "LEADING";
  EgdsIconPosition["TRAILING"] = "TRAILING";
})(EgdsIconPosition || (EgdsIconPosition = {}));
/** Option for selecting an infant seat on a plane. */
export var EgdsInfantSeat;
(function (EgdsInfantSeat) {
  EgdsInfantSeat["IN_SEAT"] = "IN_SEAT";
  EgdsInfantSeat["ON_LAP"] = "ON_LAP";
})(EgdsInfantSeat || (EgdsInfantSeat = {}));
/** Represents the size of the text in a EGDS Standard Link */
export var EgdsLinkSize;
(function (EgdsLinkSize) {
  EgdsLinkSize["LARGE"] = "LARGE";
  EgdsLinkSize["MEDIUM"] = "MEDIUM";
  EgdsLinkSize["SMALL"] = "SMALL";
})(EgdsLinkSize || (EgdsLinkSize = {}));
/**
 *
 *     This should only be used in unique design cases where the UX changes frequently and specific product requirements must be met.
 *     Most teams should just use String over EGDSParagraph or EGDSText.
 *
 *     https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography
 *
 */
export var EgdsParagraphStyle;
(function (EgdsParagraphStyle) {
  EgdsParagraphStyle["P1"] = "P1";
  EgdsParagraphStyle["P2"] = "P2";
  EgdsParagraphStyle["P3"] = "P3";
})(EgdsParagraphStyle || (EgdsParagraphStyle = {}));
export var EgdsTextListItemStyle;
(function (EgdsTextListItemStyle) {
  EgdsTextListItemStyle["DEFAULT"] = "DEFAULT";
  EgdsTextListItemStyle["EMPHASIS"] = "EMPHASIS";
  EgdsTextListItemStyle["LOYALTY"] = "LOYALTY";
  EgdsTextListItemStyle["NEGATIVE"] = "NEGATIVE";
  EgdsTextListItemStyle["POSITIVE"] = "POSITIVE";
})(EgdsTextListItemStyle || (EgdsTextListItemStyle = {}));
/** https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography */
export var EgdsTextListSize;
(function (EgdsTextListSize) {
  EgdsTextListSize["LIST_1"] = "LIST_1";
  EgdsTextListSize["LIST_2"] = "LIST_2";
  EgdsTextListSize["LIST_3"] = "LIST_3";
})(EgdsTextListSize || (EgdsTextListSize = {}));
/**
 *  This represents UI variations of EGDS List
 *         figma: https://www.figma.com/file/IHAiPyllJIlO6g4f2qdNW4zq/Typography?node-id=11931%3A11857
 *
 */
export var EgdsTextListType;
(function (EgdsTextListType) {
  EgdsTextListType["BULLET"] = "BULLET";
  EgdsTextListType["ICON"] = "ICON";
  EgdsTextListType["NO_BULLET"] = "NO_BULLET";
  EgdsTextListType["NUMBER"] = "NUMBER";
})(EgdsTextListType || (EgdsTextListType = {}));
/**
 *
 * represents the possible UI theme colors.
 * https://uitk.expedia.biz/en_US/components/cards#global_loyalty_card_patterns
 * https://www.figma.com/file/x9PecZwsESUjIsB1s4D676/Visual-Identity-Refinement?type=design&node-id=6873-216790&mode=design&t=zsn1QbwSKBHJ7OD5-0
 *
 */
export var EgdsThemeProviderColor;
(function (EgdsThemeProviderColor) {
  EgdsThemeProviderColor["BRIGHT_1"] = "BRIGHT_1";
  EgdsThemeProviderColor["DARK_1"] = "DARK_1";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_EXTRA_HIGH"] = "GLOBAL_LOYALTY_EXTRA_HIGH";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT"] = "GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_HIGH"] = "GLOBAL_LOYALTY_HIGH";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_HIGH_BRIGHT"] = "GLOBAL_LOYALTY_HIGH_BRIGHT";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_LOW"] = "GLOBAL_LOYALTY_LOW";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_LOW_BRIGHT"] = "GLOBAL_LOYALTY_LOW_BRIGHT";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_MID"] = "GLOBAL_LOYALTY_MID";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_MID_BRIGHT"] = "GLOBAL_LOYALTY_MID_BRIGHT";
  EgdsThemeProviderColor["GLOBAL_LOYALTY_STANDARD"] = "GLOBAL_LOYALTY_STANDARD";
  EgdsThemeProviderColor["LIGHT_1"] = "LIGHT_1";
  EgdsThemeProviderColor["MARKETING_1"] = "MARKETING_1";
  EgdsThemeProviderColor["MARKETING_2"] = "MARKETING_2";
  EgdsThemeProviderColor["NONE"] = "NONE";
  EgdsThemeProviderColor["STANDARD"] = "STANDARD";
})(EgdsThemeProviderColor || (EgdsThemeProviderColor = {}));
/** Travelers types used on travelers selector */
export var EgdsTravelerType;
(function (EgdsTravelerType) {
  EgdsTravelerType["ADULT"] = "ADULT";
  EgdsTravelerType["CHILD"] = "CHILD";
  EgdsTravelerType["INFANT"] = "INFANT";
  EgdsTravelerType["SENIOR"] = "SENIOR";
  EgdsTravelerType["YOUTH"] = "YOUTH";
})(EgdsTravelerType || (EgdsTravelerType = {}));
/** Represents different type of travelers validation. */
export var EgdsTravelersValidationType;
(function (EgdsTravelersValidationType) {
  /** Used to validate child age required. */
  EgdsTravelersValidationType["CHILD_AGE_REQUIRED"] = "CHILD_AGE_REQUIRED";
  /** Used to validate maximum number of allowed infants in seat. */
  EgdsTravelersValidationType["MANY_INFANT_IN_SEAT"] = "MANY_INFANT_IN_SEAT";
  /** Used to validate maximum number of allowed infants on lap. */
  EgdsTravelersValidationType["MANY_INFANT_ON_LAP"] = "MANY_INFANT_ON_LAP";
  /** Used to validate maximum allowed travelers for search. */
  EgdsTravelersValidationType["MAX_TRAVELERS"] = "MAX_TRAVELERS";
})(EgdsTravelersValidationType || (EgdsTravelersValidationType = {}));
/** Defines how typeahead results should be displayed on client. */
export var EgdsTypeaheadRenderType;
(function (EgdsTypeaheadRenderType) {
  EgdsTypeaheadRenderType["ADAPTIVE"] = "ADAPTIVE";
  EgdsTypeaheadRenderType["DIALOG"] = "DIALOG";
  EgdsTypeaheadRenderType["MENU"] = "MENU";
})(EgdsTypeaheadRenderType || (EgdsTypeaheadRenderType = {}));
/**
 *
 * Used to specify event type for an interaction.
 * See https://github.expedia.biz/Brand-Expedia/uis-prime-schemas/blob/master/schemas/micro/platform/referrer.json#L26
 *
 */
export var EgReferrerEventType;
(function (EgReferrerEventType) {
  /** Used to track a click on a UI component. */
  EgReferrerEventType["CLICK"] = "CLICK";
  /** Used to track an impression of a UI component. */
  EgReferrerEventType["IMPRESSION"] = "IMPRESSION";
})(EgReferrerEventType || (EgReferrerEventType = {}));
/** supported sizes of icons */
export var IconSize;
(function (IconSize) {
  IconSize["EXTRA_SMALL"] = "EXTRA_SMALL";
  IconSize["LARGE"] = "LARGE";
  IconSize["MEDIUM"] = "MEDIUM";
  /** @deprecated LARGE icon size changed from 48px to 32px. Use LARGE instead of MEDIUM_LARGE */
  IconSize["MEDIUM_LARGE"] = "MEDIUM_LARGE";
  IconSize["SMALL"] = "SMALL";
  IconSize["XLARGE"] = "XLARGE";
})(IconSize || (IconSize = {}));
/** Supported themes of spotlights. See the prop definitions here: https://www.figma.com/file/Cq6XtgvVIEriWmWmVIMPzNOE/Icons?node-id=6402%3A115 */
export var IconSpotLight;
(function (IconSpotLight) {
  IconSpotLight["INFO"] = "INFO";
  IconSpotLight["POSITIVE"] = "POSITIVE";
  IconSpotLight["STANDARD"] = "STANDARD";
  IconSpotLight["WARNING"] = "WARNING";
})(IconSpotLight || (IconSpotLight = {}));
/** Supported themes of icons. See the prop definitions here: http://uitk-react.tools.expedia.com/expedia/en_US/components/icons */
export var IconTheme;
(function (IconTheme) {
  IconTheme["DEFAULT"] = "default";
  IconTheme["EMPHASIS"] = "emphasis";
  IconTheme["INVERSE"] = "inverse";
  IconTheme["NEGATIVE"] = "negative";
  IconTheme["POSITIVE"] = "positive";
})(IconTheme || (IconTheme = {}));
/** Represents insurance dialog action types */
export var InsurTechDialogActionType;
(function (InsurTechDialogActionType) {
  InsurTechDialogActionType["CLOSE"] = "CLOSE";
  InsurTechDialogActionType["OPEN"] = "OPEN";
  InsurTechDialogActionType["OPEN_QUERY"] = "OPEN_QUERY";
})(InsurTechDialogActionType || (InsurTechDialogActionType = {}));
/** Represents insurance dialog trigger types link or button */
export var InsurTechExperienceDialogTriggerType;
(function (InsurTechExperienceDialogTriggerType) {
  InsurTechExperienceDialogTriggerType["BUTTON"] = "BUTTON";
  InsurTechExperienceDialogTriggerType["LINK"] = "LINK";
})(InsurTechExperienceDialogTriggerType || (InsurTechExperienceDialogTriggerType = {}));
/** Possible values for UITK mark's orientation prop */
export var Orientation;
(function (Orientation) {
  /** Landscape orientation */
  Orientation["LANDSCAPE"] = "LANDSCAPE";
  /** Portrait orientation */
  Orientation["PORTRAIT"] = "PORTRAIT";
})(Orientation || (Orientation = {}));
/**
 *
 *     Languages vary in how they handle plurals of nouns or unit expressions ("hour" vs "hours", and so on).
 *     Some languages have two forms, like English; some languages have only a single form; and some languages have multiple forms. CLDR uses short, mnemonic tags for these plural categories.
 *     http://cldr.unicode.org/index/cldr-spec/plural-rules
 *
 */
export var PluralCategory;
(function (PluralCategory) {
  PluralCategory["FEW"] = "FEW";
  PluralCategory["MANY"] = "MANY";
  PluralCategory["ONE"] = "ONE";
  PluralCategory["OTHER"] = "OTHER";
  PluralCategory["TWO"] = "TWO";
  PluralCategory["ZERO"] = "ZERO";
})(PluralCategory || (PluralCategory = {}));
/** Privacy tracking user preference. We can retrieve info on if the site allows tracking from Site Info and null can also be used to represent a selection or not */
export var PrivacyTrackingState;
(function (PrivacyTrackingState) {
  PrivacyTrackingState["CAN_NOT_TRACK"] = "CAN_NOT_TRACK";
  PrivacyTrackingState["CAN_TRACK"] = "CAN_TRACK";
})(PrivacyTrackingState || (PrivacyTrackingState = {}));
/** Used to highlight or convey information. For example, positive messaging like free cancellation may be green. */
export var TextTheme;
(function (TextTheme) {
  TextTheme["EMPHASIS"] = "EMPHASIS";
  TextTheme["INVERSE"] = "INVERSE";
  TextTheme["LOYALTY"] = "LOYALTY";
  TextTheme["NEGATIVE"] = "NEGATIVE";
  TextTheme["POSITIVE"] = "POSITIVE";
  TextTheme["PRIMARY"] = "PRIMARY";
  TextTheme["SECONDARY"] = "SECONDARY";
})(TextTheme || (TextTheme = {}));
/** Used to highlight or convey information. For example, Payable amount in the price summary module of the checkout page. */
export var TextWeight;
(function (TextWeight) {
  TextWeight["BOLD"] = "BOLD";
  TextWeight["MEDIUM"] = "MEDIUM";
  TextWeight["REGULAR"] = "REGULAR";
})(TextWeight || (TextWeight = {}));
/** Specifies whether to open the link in the same user interface context or in a different context */
export var UiLinkTarget;
(function (UiLinkTarget) {
  UiLinkTarget["EXTERNAL"] = "EXTERNAL";
  UiLinkTarget["INTERNAL"] = "INTERNAL";
})(UiLinkTarget || (UiLinkTarget = {}));
export const ChatbotButtonFragmentDoc = gql`
    fragment chatbotButton on UIPrimaryButton {
  primary
  icon {
    token
  }
}
    `;
export const IntelligentAgentChatbotFragmentDoc = gql`
    fragment IntelligentAgentChatbot on IntelligentAgentChatbotResponse {
  authToken
  channelOriginId
  button {
    ...chatbotButton
  }
  __typename
  scriptUrl
}
    ${ChatbotButtonFragmentDoc}`;
export const ZendeskChatbotFragmentDoc = gql`
    fragment ZendeskChatbot on ZendeskChatbotResponse {
  key
  formIds
  button {
    ...chatbotButton
  }
  __typename
}
    ${ChatbotButtonFragmentDoc}`;
export const ChatbotFragmentDoc = gql`
    fragment Chatbot on DocumentationChatbotResponse {
  ... on IntelligentAgentChatbotResponse {
    ...IntelligentAgentChatbot
  }
  ... on ZendeskChatbotResponse {
    ...ZendeskChatbot
  }
}
    ${IntelligentAgentChatbotFragmentDoc}
${ZendeskChatbotFragmentDoc}`;
export const CustomerSurveyLinkFragmentDoc = gql`
    fragment customerSurveyLink on UILinkAction {
  resource {
    value
  }
}
    `;
export const CustomerSurveyButtonFragmentDoc = gql`
    fragment customerSurveyButton on UIPrimaryButton {
  action {
    ...customerSurveyLink
  }
  primary
  icon {
    token
  }
}
    ${CustomerSurveyLinkFragmentDoc}`;
export const CustomerSurveyFragmentDoc = gql`
    fragment customerSurvey on DocumentationCustomerSurveyResponse {
  button {
    ...customerSurveyButton
  }
}
    ${CustomerSurveyButtonFragmentDoc}`;
export const BreadcrumbLinkFragmentDoc = gql`
    fragment breadcrumbLink on EGDSStandardLink {
  text
  action {
    target
    resource {
      value
    }
  }
}
    `;
export const BreadcrumbTextFragmentDoc = gql`
    fragment breadcrumbText on EGDSStylizedText {
  text
}
    `;
export const DocumentDataFragmentDoc = gql`
    fragment documentData on DocumentationDocumentSuccessResponse {
  content
  intro
  title
  type
  format
  thumbnail {
    url
  }
  banner {
    message
    icon {
      token
    }
  }
  notifications {
    message
    icon {
      token
    }
  }
  navigationTrail {
    __typename
    ...breadcrumbLink
    ...breadcrumbText
  }
}
    ${BreadcrumbLinkFragmentDoc}
${BreadcrumbTextFragmentDoc}`;
export const DocumentErrorActionFragmentDoc = gql`
    fragment documentErrorAction on UILinkAction {
  resource {
    value
  }
}
    `;
export const DocumentErrorFragmentDoc = gql`
    fragment documentError on DocumentationDocumentErrorResponse {
  notifications {
    message
    icon {
      token
    }
  }
  messageText {
    text
  }
  descriptionText {
    text
  }
  buttons {
    primary
    action {
      ...documentErrorAction
    }
  }
}
    ${DocumentErrorActionFragmentDoc}`;
export const ReactionButtonFragmentDoc = gql`
    fragment reactionButton on UIPrimaryButton {
  primary
  icon {
    title
    token
    size
  }
}
    `;
export const ReactionFormFragmentDoc = gql`
    fragment reactionForm on SuggestionForm {
  input {
    label
    placeholder
    validations {
      errorMessage
    }
  }
  submit {
    primary
  }
  thankYouMessage {
    text
  }
}
    `;
export const HeaderLogoFragmentDoc = gql`
    fragment headerLogo on DocumentationHeaderLogoElement {
  altText
  imgSrc {
    url
    description
  }
}
    `;
export const HeaderLinkFragmentDoc = gql`
    fragment headerLink on EGDSStandardLink {
  text
  action {
    target
    resource {
      value
    }
  }
}
    `;
export const HeaderElementFragmentDoc = gql`
    fragment headerElement on DocumentationHeaderElement {
  ...headerLink
  ...headerLogo
}
    ${HeaderLinkFragmentDoc}
${HeaderLogoFragmentDoc}`;
export const SearchBoxFragmentDoc = gql`
    fragment searchBox on DocumentationHeaderSearchBoxElement {
  icon {
    title
    description
    size
    id
    token
  }
  indexFileUri {
    value
  }
  placeholder
}
    `;
export const LanguageItemFragmentDoc = gql`
    fragment languageItem on DocumentationHeaderMenuLinkItem {
  title
  label
  action {
    resource {
      value
    }
  }
  icon {
    token
  }
}
    `;
export const LanguageMenuFragmentDoc = gql`
    fragment languageMenu on DocumentationMenu {
  subMenus {
    ... on DocumentationHeaderSubMenu {
      items {
        ...languageItem
      }
    }
  }
  leadingIcon {
    token
  }
  trailingIcon {
    token
  }
}
    ${LanguageItemFragmentDoc}`;
export const AuthenticationLinkFragmentDoc = gql`
    fragment authenticationLink on UILinkAction {
  resource {
    value
  }
  target
}
    `;
export const AuthenticatedUserMenuButtonFragmentDoc = gql`
    fragment authenticatedUserMenuButton on UISecondaryButton {
  primary
  icon {
    token
  }
  action {
    ...authenticationLink
  }
}
    ${AuthenticationLinkFragmentDoc}`;
export const AuthenticatedUserSubMenuFragmentDoc = gql`
    fragment authenticatedUserSubMenu on DocumentationHeaderSubMenu {
  title
  items {
    title
    ... on EGDSMenuStandardItem {
      icon {
        token
      }
    }
    ... on DocumentationHeaderMenuLinkItem {
      action {
        resource {
          value
        }
      }
    }
  }
}
    `;
export const AuthenticatedUserMenuFragmentDoc = gql`
    fragment authenticatedUserMenu on AuthenticatedUserMenu {
  title
  icon {
    token
  }
  buttons {
    ...authenticatedUserMenuButton
  }
  subMenus {
    ...authenticatedUserSubMenu
  }
}
    ${AuthenticatedUserMenuButtonFragmentDoc}
${AuthenticatedUserSubMenuFragmentDoc}`;
export const AuthenticationFragmentDoc = gql`
    fragment authentication on Authentication {
  menu {
    ...authenticatedUserMenu
  }
  buttons {
    primary
    icon {
      token
    }
    action {
      ...authenticationLink
    }
  }
}
    ${AuthenticatedUserMenuFragmentDoc}
${AuthenticationLinkFragmentDoc}`;
export const DocumentationHeaderFragmentDoc = gql`
    fragment documentationHeader on DocumentationHeaderResponse {
  hubName
  logo {
    ...headerLogo
  }
  primaryNavigation {
    ...headerElement
  }
  secondaryNavigation {
    ...headerElement
  }
  searchBox {
    ...searchBox
  }
  languageMenu {
    ...languageMenu
  }
  authentication {
    ...authentication
  }
  notifications {
    message
    icon {
      token
    }
  }
}
    ${HeaderLogoFragmentDoc}
${HeaderElementFragmentDoc}
${SearchBoxFragmentDoc}
${LanguageMenuFragmentDoc}
${AuthenticationFragmentDoc}`;
export const RelationshipsLinkFragmentDoc = gql`
    fragment relationshipsLink on EGDSStandardLink {
  text
  disabled
  size
  action {
    target
    resource {
      value
    }
  }
  icon {
    size
    token
    title
    id
    description
  }
}
    `;
export const RelatedTitleFragmentDoc = gql`
    fragment relatedTitle on EGDSStylizedText {
  text
  weight
}
    `;
export const RelatedUrlFragmentDoc = gql`
    fragment relatedUrl on UILinkAction {
  resource {
    value
  }
}
    `;
export const RelatedSubtitleFragmentDoc = gql`
    fragment relatedSubtitle on EGDSPlainText {
  text
}
    `;
export const RelatedElementFragmentDoc = gql`
    fragment relatedElement on DocumentationRelatedDocument {
  url {
    ...relatedUrl
  }
  title {
    ...relatedTitle
  }
  subtitle {
    ...relatedSubtitle
  }
}
    ${RelatedUrlFragmentDoc}
${RelatedTitleFragmentDoc}
${RelatedSubtitleFragmentDoc}`;
export const RelatedSectionFragmentDoc = gql`
    fragment relatedSection on DocumentationRelatedSection {
  title {
    ...relatedTitle
  }
  elements {
    ...relatedElement
  }
}
    ${RelatedTitleFragmentDoc}
${RelatedElementFragmentDoc}`;
export const RelationshipsDataFragmentDoc = gql`
    fragment relationshipsData on DocumentationRelationshipsSuccessResponse {
  next {
    ...relationshipsLink
  }
  previous {
    ...relationshipsLink
  }
  related {
    ...relatedSection
  }
  notifications {
    message
    icon {
      token
    }
  }
}
    ${RelationshipsLinkFragmentDoc}
${RelatedSectionFragmentDoc}`;
export const RelationshipsErrorFragmentDoc = gql`
    fragment relationshipsError on DocumentationRelationshipsErrorResponse {
  message
  notifications {
    message
    icon {
      token
    }
  }
}
    `;
export const SandboxDataFragmentDoc = gql`
    fragment sandboxData on DocumentationDocumentSuccessResponse {
  content
  notifications {
    message
    icon {
      token
    }
  }
}
    `;
export const DocErrorFragmentDoc = gql`
    fragment docError on DocumentationDocumentErrorResponse {
  messageText {
    text
  }
  descriptionText {
    text
  }
  buttons {
    primary
    action {
      ...documentErrorAction
    }
  }
  notifications {
    message
    icon {
      token
    }
  }
}
    ${DocumentErrorActionFragmentDoc}`;
export const IconFragmentDoc = gql`
    fragment icon on Icon {
  token
  description
  title
  id
}
    `;
export const LinkFragmentDoc = gql`
    fragment link on EGDSStandardLink {
  text
  action {
    target
    resource {
      value
    }
  }
  icon {
    ...icon
  }
}
    ${IconFragmentDoc}`;
export const NavigationLinkFragmentDoc = gql`
    fragment navigationLink on DocumentationNavigationLinkElement {
  link {
    ...link
  }
  badge {
    text
    size
    theme
  }
}
    ${LinkFragmentDoc}`;
export const ExpandoFragmentDoc = gql`
    fragment expando on DocumentationNavigationExpandoElement {
  label
  navigationElements {
    ...navigationLink
  }
  badge {
    text
    size
    theme
  }
}
    ${NavigationLinkFragmentDoc}`;
export const NavigationElementFragmentDoc = gql`
    fragment navigationElement on DocumentationNavigationElement {
  ...navigationLink
  ...expando
}
    ${NavigationLinkFragmentDoc}
${ExpandoFragmentDoc}`;
export const SectionFragmentDoc = gql`
    fragment section on DocumentationNavigationSectionElement {
  heading
  navigationElements {
    ...navigationElement
  }
  badge {
    text
    size
    theme
  }
  icon {
    token
    description
    title
    id
  }
}
    ${NavigationElementFragmentDoc}`;
export const ChatbotDataDocument = gql`
    query ChatbotData($hubId: String!, $context: ContextInput!, $tabId: String!) {
  documentation(hubId: $hubId, context: $context) {
    chatbot(tabId: $tabId) {
      ... on DocumentationChatbotResponse {
        ...Chatbot
      }
      ... on NoConfiguration {
        __typename
      }
    }
  }
}
    ${ChatbotFragmentDoc}`;
/**
 * __useChatbotDataQuery__
 *
 * To run a query within a React component, call `useChatbotDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotDataQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      context: // value for 'context'
 *      tabId: // value for 'tabId'
 *   },
 * });
 */
export function useChatbotDataQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(ChatbotDataDocument, options);
}
export function useChatbotDataLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(ChatbotDataDocument, options);
}
export const CustomerSurveyDocument = gql`
    query CustomerSurvey($hubId: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    customerSurvey {
      ...customerSurvey
    }
  }
}
    ${CustomerSurveyFragmentDoc}`;
/**
 * __useCustomerSurveyQuery__
 *
 * To run a query within a React component, call `useCustomerSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSurveyQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useCustomerSurveyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(CustomerSurveyDocument, options);
}
export function useCustomerSurveyLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(CustomerSurveyDocument, options);
}
export const DocumentDocument = gql`
    query Document($hubId: String!, $documentId: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    document(id: $documentId) {
      ...documentData
      ...documentError
    }
  }
}
    ${DocumentDataFragmentDoc}
${DocumentErrorFragmentDoc}`;
/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      documentId: // value for 'documentId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(DocumentDocument, options);
}
export function useDocumentLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(DocumentDocument, options);
}
export const FeedbackDocument = gql`
    query Feedback($hubId: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    feedback {
      question {
        text
      }
      suggestionForm {
        ...reactionForm
      }
      thumpsDown {
        ...reactionButton
      }
      thumpsUp {
        ...reactionButton
      }
      notifications {
        message
        icon {
          token
        }
      }
    }
  }
}
    ${ReactionFormFragmentDoc}
${ReactionButtonFragmentDoc}`;
/**
 * __useFeedbackQuery__
 *
 * To run a query within a React component, call `useFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useFeedbackQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(FeedbackDocument, options);
}
export function useFeedbackLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(FeedbackDocument, options);
}
export const HeaderDocument = gql`
    query Header($hubId: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    header {
      ...documentationHeader
    }
  }
}
    ${DocumentationHeaderFragmentDoc}`;
/**
 * __useHeaderQuery__
 *
 * To run a query within a React component, call `useHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useHeaderQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(HeaderDocument, options);
}
export function useHeaderLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(HeaderDocument, options);
}
export const RelationshipsDocument = gql`
    query relationships($hubId: String!, $documentId: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    relationships(id: $documentId) {
      ...relationshipsData
      ...relationshipsError
    }
  }
}
    ${RelationshipsDataFragmentDoc}
${RelationshipsErrorFragmentDoc}`;
/**
 * __useRelationshipsQuery__
 *
 * To run a query within a React component, call `useRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      documentId: // value for 'documentId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useRelationshipsQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(RelationshipsDocument, options);
}
export function useRelationshipsLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(RelationshipsDocument, options);
}
export const SandboxDocument = gql`
    query Sandbox($hubId: String!, $documentId: String, $referencePath: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    sandbox(referencePath: $referencePath, documentId: $documentId) {
      ...sandboxData
      ...docError
    }
  }
}
    ${SandboxDataFragmentDoc}
${DocErrorFragmentDoc}`;
/**
 * __useSandboxQuery__
 *
 * To run a query within a React component, call `useSandboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useSandboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSandboxQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      documentId: // value for 'documentId'
 *      referencePath: // value for 'referencePath'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useSandboxQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(SandboxDocument, options);
}
export function useSandboxLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(SandboxDocument, options);
}
export const SearchDocument = gql`
    query Search($hubId: String!, $context: ContextInput!, $searchTerm: String!) {
  documentation(hubId: $hubId, context: $context) {
    search(term: $searchTerm) {
      ... on DocumentationSearchResponse {
        searchResults {
          title
          path
          intro
          parentTitles
        }
      }
    }
  }
}
    `;
/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      context: // value for 'context'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSearchQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(SearchDocument, options);
}
export const SidebarDocument = gql`
    query Sidebar($hubId: String!, $documentId: String!, $context: ContextInput!) {
  documentation(hubId: $hubId, context: $context) {
    sidebar(documentId: $documentId) {
      spaceDocumentIds
      topLevelNavigationItem {
        id
        isSpace
      }
      elements {
        ...section
      }
      heading {
        title {
          text
        }
      }
      notifications {
        message
        icon {
          token
        }
      }
    }
  }
}
    ${SectionFragmentDoc}`;
/**
 * __useSidebarQuery__
 *
 * To run a query within a React component, call `useSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      documentId: // value for 'documentId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useSidebarQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery(SidebarDocument, options);
}
export function useSidebarLazyQuery(baseOptions) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery(SidebarDocument, options);
}