import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
const LoadingSkeleton = () => {
  return React.createElement("div", {
    className: "loading-skeleton"
  }, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(UitkLayoutFlex, {
    space: "six",
    direction: "column"
  }, React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "secondary"
  }))), React.createElement(ViewMedium, null, React.createElement(UitkLayoutFlex, {
    space: "six",
    direction: "column"
  }, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "three"
  }, React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }))), React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "three"
  }, React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }))), React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    space: "three"
  }, React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  }), React.createElement(UitkLineSkeleton, {
    animation: true,
    type: "primary"
  })))))));
};
export default LoadingSkeleton;