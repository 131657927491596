import { useMemo } from 'react';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { events } from './events';
import useNavigationParams from '../useNavigationParams';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useLocation } from 'react-router-dom';
const useAnalytics = () => {
  const {
    documentId,
    hubId,
    pageName
  } = useNavigationParams();
  const {
    context
  } = useBexApi();
  const {
    pathname
  } = useLocation();
  const commonEventProperties = useMemo(() => {
    return {
      pageName,
      hubId,
      locale: context.locale,
      article: {
        documentId: `/${documentId === '' ? hubId : documentId}`,
        articleLink: pathname
      }
    };
  }, [documentId]);
  const track = useEgClickstream();
  return {
    trackPageView: () => {
      track(events.pageViewed(commonEventProperties));
    },
    trackFeedbackIntent: feedback => track(events.feedbackIntentAdded(feedback, commonEventProperties)),
    trackFeedbackReason: feedback => track(events.feedbackReasonAdded(feedback, commonEventProperties)),
    trackSearchInput: search => track(events.searchInputted(search, commonEventProperties)),
    trackApiExplorerEndpointExpand: action => track(events.apiExplorerEndpointExpanded(action, commonEventProperties)),
    trackApiExplorerRequestButtonClick: action => track(events.apiExplorerRequestButtonClick(action, commonEventProperties)),
    trackLinkSelected: id => track(events.linkSelected(id, commonEventProperties)),
    trackSignInSelected: () => track(events.signInSelected(commonEventProperties)),
    trackSignOutSubmitted: () => track(events.signOutSubmitted(commonEventProperties))
  };
};
export default useAnalytics;