import React, { createContext, useState } from 'react';
export const GraphqlErrorsContext = createContext(null);
const GraphqlErrorsContextProvider = ({
  children
}) => {
  const [hasError, setHasError] = useState(false);
  return React.createElement(GraphqlErrorsContext.Provider, {
    value: {
      hasError,
      setHasError
    }
  }, children);
};
export default GraphqlErrorsContextProvider;