import { SandboxDocument } from '../graphql/__generated__/types';
import { TabGroups } from '@eg-markdown-renderer/core';
import Storage from 'utils/storage';
export const SUPPLY_LODGING_PATH = '/supply/lodging';
export const sandboxLinkOnClickHandler = (e, href, path, history) => {
  const resolveRelativePath = (internalPath, internalHref) => {
    const traverseBackMatch = internalHref.match(/\.\.\//gm);
    const splitParams = internalPath.split('/').filter(i => i !== '');
    traverseBackMatch === null || traverseBackMatch === void 0 ? void 0 : traverseBackMatch.forEach(() => {
      splitParams.pop();
    });
    const absoluteHref = internalHref.replaceAll('../', '');
    return splitParams.length ? `${splitParams.join('/')}/${absoluteHref}` : absoluteHref;
  };
  const isExternalLink = /^http/.test(href) || /^www/.test(href);
  if (!isExternalLink) {
    e.preventDefault();
    let relativePath = href;
    if (path) {
      const slashLastIndex = path.lastIndexOf('/');
      const pathWithoutFile = path.substring(0, slashLastIndex);
      relativePath = resolveRelativePath(pathWithoutFile, href);
    }
    history.push(relativePath ? `${history.location.pathname}?path=${relativePath}` : location.pathname);
  }
};
export const markdownLinkOnClickHandler = (href, pathname, hubId, history) => {
  const resolvedLink = resolveMarkdownAbsoluteLink(href, pathname, hubId, window.origin);
  if (isAnchorTagOnTheSamePage(href)) {
    window.location.href = href;
  } else {
    const target = resolveLinkTarget(href, window.origin);
    if (target === '_blank') {
      window.open(resolvedLink);
    } else {
      history.push(resolvedLink);
    }
  }
};
const isAnchorTagOnTheSamePage = href => href.startsWith('#');
export const sandboxDocumentQuery = async args => {
  const {
    hubId,
    documentId,
    context,
    client,
    pathQueryParam
  } = args;
  const variables = {
    hubId,
    context,
    referencePath: documentId
  };
  if (pathQueryParam) {
    variables.documentId = pathQueryParam;
  }
  const {
    data,
    error
  } = await client.query({
    query: SandboxDocument,
    variables
  });
  const content = data === null || data === void 0 ? void 0 : data.documentation.sandbox;
  return error ? 'Error' : content === null || content === void 0 ? void 0 : content.content;
};
const resolveMarkdownAbsoluteLink = (href, _pathname, _hubName, origin) => {
  if (href.startsWith(origin)) {
    return href.replace(origin, '');
  }
  return href;
};
const resolveLinkTarget = (href, origin) => {
  if (new URL(href).pathname === SUPPLY_LODGING_PATH) return '_blank';
  if (href.startsWith(origin)) {
    return '_self';
  }
  return '_blank';
};
export const tabOnClickHandler = (key, value, history) => {
  let params = '';
  const selectedTabs = getSelectedTabGroups();
  const obj = {
    ...selectedTabs,
    [key]: value
  };
  Storage === null || Storage === void 0 ? void 0 : Storage.setItem('selectedTabGroups', JSON.stringify(obj));
  Object.keys(obj).forEach(item => {
    params += `&${item}=${obj[item]}`;
  });
  history.push(`${history.location.pathname}?${params === null || params === void 0 ? void 0 : params.substring(1)}`);
};
export const getSelectedTabGroups = () => {
  return Storage.getItem('selectedTabGroups') ? JSON.parse(Storage.getItem('selectedTabGroups')) : {};
};
export const getTabsQueryParams = query => {
  let selectedTabs = {};
  Object.keys(TabGroups).forEach(key => {
    const queryParam = query.get(TabGroups[key]);
    if (queryParam) {
      selectedTabs = {
        ...selectedTabs,
        [TabGroups[key]]: queryParam
      };
    }
  });
  return selectedTabs;
};