import { useBexApi } from '@shared-ui/bex-api-context';
import React from 'react';
import { UitkSpacing } from 'uitk-react-spacing';
import { GenericErrorMessage } from '../common/generic-error-message';
import { useDocumentQuery } from '../../graphql/__generated__/types';
import { ErrorBoundary } from 'bernie-view';
import EgMarkdownRenderer from '@eg-markdown-renderer/core';
import { useMarkdownRendererOptions } from '../../hooks/useMarkdownRendererOptions';
import useNavigationParams from '../../hooks/useNavigationParams';
export const FOOTER_DOCUMENT_ID = '_common/footer';
const Footer = () => {
  const {
    hubId
  } = useNavigationParams();
  const {
    componentsOptions
  } = useMarkdownRendererOptions(hubId);
  const {
    context
  } = useBexApi();
  const {
    data,
    loading,
    error
  } = useDocumentQuery({
    variables: {
      context,
      hubId,
      documentId: FOOTER_DOCUMENT_ID
    }
  });
  const isErrorDataResponse = (data === null || data === void 0 ? void 0 : data.documentation.document.__typename) === 'DocumentationDocumentErrorResponse';
  if (!data || loading || error || isErrorDataResponse) {
    return null;
  }
  const {
    content
  } = data.documentation.document;
  return React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'sixteen',
      blockend: 'twelve'
    }
  }, React.createElement("div", null, React.createElement(UitkSpacing, {
    margin: {
      inline: 'half',
      block: 'sixteen'
    }
  }, React.createElement(ErrorBoundary, {
    label: "document.body",
    key: "document.body",
    fallback: React.createElement(GenericErrorMessage, null)
  }, React.createElement("div", {
    className: "md-renderer-wrapper uitk-type-center footer"
  }, React.createElement(EgMarkdownRenderer, {
    mdBody: content,
    componentsOptions: componentsOptions
  }))))));
};
export default Footer;